import React, { ChangeEvent, useEffect, useState } from "react";
import style from "../../styles/main.module.css";
import Button from "../../components/button";
import DashboardCard from "../../components/dashboard-card";
import Table from "../../components/table";
import {
  ActivateUser,
  DeleteUser,
  GetUsers,
  UpdateKeys,
  getKeys,
} from "../../services/api-calls";
import { useAuth } from "../../contexts/Authcontext";
import { useNavigate } from "react-router";
import Loaderbtn from "../../components/Loaderbtn";
import { toast } from "react-toastify";
import LoginForm from "../../components/loginform";
const CardContent = [
  { name: "Total Users", value: 10 },
  { name: "Pending Users", value: 10 },
  { name: "Total Website", value: 1212 },
];
const inputsForm = [
  {
    text: "ChatGPT Key",
    placeHolder: "Enter chatgpt key...",
    id: "gpt",
    type: "gpt",
  },
  {
    text: "Google API",
    placeHolder: "Enter Google API keys...",
    id: "google_api",
    type: "google_api",
  },
  {
    text: "Google Search API",
    placeHolder: "Enter Search Google API keys...",
    id: "google_api_search",
    type: "google_api_search",
  },
];

interface AdminKeys {
  gpt: string;
  google_api: string;
  google_api_search: string;
}
const thead = ["Username", "Email", "Date Joined", "Is Active", "Action"];

export default function AdminDashboard() {
  const [users, setUsers] = useState<any[]>();
  const { isAdminAuthed, AdminLogout, AdminToken } = useAuth();

  const [userInputs, setUserInputs] = useState<AdminKeys>({
    google_api: "",
    gpt: "",
    google_api_search: "",
  });

  const [Loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(false);

  const Navigation = useNavigate();

  const DeleteUsers = async (email: string) => {
    try {
      setLoading(true);
      const Delete = await DeleteUser({ email: email }, AdminToken);
      setLoading(false);
      if (Delete) {
        setUsers(users?.filter((a) => a.email !== email));
        return toast.success("Success");
      }

      toast.error("Faild to remove user");
    } catch (error) {
      setLoading(false);

      const errorText = (error as Error).message;
      alert(errorText);
    }
  };

  const ActivateUserbtn = async (email: string) => {
    try {
      const Activate = await ActivateUser({ email }, AdminToken);
      if (Activate) {
        await loadUsers();
        return toast.success("Success");
      }
      toast.error("Faild to Activate user");
    } catch (error) {
      const errorText = (error as Error).message;
      toast.success(errorText);
    }
  };

  const loadUsers = async () => {
    try {
      const data = await GetUsers(AdminToken);
      if (data) {
        const keys = await getKeys(AdminToken);
        if (keys) setUserInputs({ ...keys });
        return setUsers(data);
      }
    } catch (error) {}
  };

  const HandleInputs = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    setUserInputs((pre) => {
      if (name === "gpt") {
        return {
          ...pre,
          gpt: value,
        };
      } else if (name === "google_api") {
        return {
          ...pre,
          google_api: value,
        };
      } else if (name === "google_api_search") {
        return {
          ...pre,
          google_api_search: value,
        };
      } else return pre;
    });
  };
  const HandleOnClick = async () => {
    try {
      if (
        !userInputs.google_api ||
        !userInputs.google_api_search ||
        !userInputs.gpt
      ) {
        return toast.error("All input field is required");
      }
      setLoading1(true);
      const UpdateMainKeys = await UpdateKeys(userInputs, AdminToken);
      setLoading1(false);

      if (UpdateMainKeys) {
        return toast.success("Success");
      }
      return toast.error("Faild to update keys");
    } catch (error) {
      setLoading1(false);
      const message = (error as Error).message;
      return toast.error(message);
    }
  };

  useEffect(() => {
    if (!isAdminAuthed) return Navigation("/admin/login");
  }, [isAdminAuthed]);

  useEffect(() => {
    if (AdminToken && isAdminAuthed) loadUsers();
  }, [AdminToken]);

  if (isAdminAuthed)
    return (
      <div className={style.DashboardContainer}>
        <div className={style.Dashboard_Heading}>
          <h2>Good Morning Admin,</h2>
          <Button
            width="100px"
            height="40px"
            text={"Logout"}
            onClick={AdminLogout}
            type="secondary"
          />
        </div>
        {/* <DashboardCard CardContent={CardContent} /> */}
        {users && (
          <Table
            rows={users}
            onUnlinkHander={DeleteUsers}
            colums={thead}
            ActivateUser={ActivateUserbtn}
            AdminTable={true}
          />
        )}
        <LoginForm
          Inputs={inputsForm}
          buttonText={Loading1 ? <Loaderbtn /> : "Update"}
          ButtonAction={Loading1 ? () => {} : HandleOnClick}
          titleText="Update keys"
          value={userInputs}
          onChangeHander={HandleInputs}
        />
      </div>
    );
  else return <></>;
}
