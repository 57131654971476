import { CreateAccountPayload, LoginUserPayload } from "./api-calls.interface";
import API_Provider from "./api-provider";

const API_URL =
  process.env.REACT_APP_MODE === "DEV"
    ? process.env.REACT_APP_API_URL_DEV!
    : process.env.REACT_APP_API_URL_PRO!;

const APIServer = new API_Provider(API_URL);

const User_ROUTE = "api/user";
const WEB_ROUTE = "api/website";
const STORY_ROUTE = "api/story";
const ADMIN_ROUTE = "api/admin";

export const getUserInfo = async (token: string) => {
  const response = await APIServer.get(`${User_ROUTE}/user-info`, token);
  return response;
};

export const CreateAccount = async (data: CreateAccountPayload) => {
  const response = await APIServer.post(`${User_ROUTE}/sign-up`, data);
  return response;
};

export const LoginUser = async (data: LoginUserPayload) => {
  const response = await APIServer.post(`${User_ROUTE}/login`, data);
  return response;
};

export const getWebsites = async (token: string) => {
  const response = await APIServer.get(`${WEB_ROUTE}/lists`, token);
  return response;
};

export const UnLinkWeb = async (domain: string, token: string) => {
  const response = await APIServer.post(
    `${WEB_ROUTE}/unlink`,
    { domain: domain },
    token
  );
  return response;
};

export const AddWebsite = async (
  username: string,
  password: string,
  domain: string,
  token: string
) => {
  const response = await APIServer.post(
    `${WEB_ROUTE}/connect`,
    { username, password, domain },
    token
  );
  return response;
};

export const CreateStorys = async (data: any, token: string) => {
  const response = await APIServer.post(
    `${STORY_ROUTE}/create-slides`,
    data,
    token
  );
  return response;
};

export const GetUsers = async (token: string) => {
  const response = await APIServer.get(`${ADMIN_ROUTE}/users`, token);
  return response;
};

export const DeleteUser = async (data: any, token: string) => {
  const response = await APIServer.post(
    `${ADMIN_ROUTE}/remove-user`,
    data,
    token
  );
  return response;
};

export const ActivateUser = async (data: any, token: string) => {
  const response = await APIServer.post(
    `${ADMIN_ROUTE}/activate-user`,
    data,
    token
  );
  return response;
};
export const LoginAdmin = async (data: LoginUserPayload) => {
  const response = await APIServer.post(`${ADMIN_ROUTE}/login`, data);
  return response;
};

export const UpdateKeys = async (data: any, token: string) => {
  const response = await APIServer.post(
    `${ADMIN_ROUTE}/update-keys`,
    data,
    token
  );
  return response;
};

export const getKeys = async (token: string) => {
  const response = await APIServer.get(`${ADMIN_ROUTE}/keys`, token);
  return response;
};
