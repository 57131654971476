import React, { useContext, useState, createContext, useEffect } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);
  const [isAuthed, setIsAuthed] = useState<boolean>(false);
  const [AdminToken, setAdminToken] = useState<string | null>(null);
  const [isAdminAuthed, setIsAdminAuthed] = useState(false);

  //admin

  const Adminauth = (newToken: string) => {
    localStorage.setItem("admin-token", newToken);
    setAdminToken(newToken);
    setIsAdminAuthed(true);
  };
  const AdminLogout = () => {
    localStorage.removeItem("admin-token");
    setAdminToken(null);
    setIsAdminAuthed(false);
  };

  const Login = (newToken: string) => {
    localStorage.setItem("user-token", newToken);
    setToken(newToken);
    setIsAuthed(true);
  };

  const Logout = () => {
    localStorage.removeItem("user-token");
    setToken(null);
    setIsAuthed(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("user-token");
    if (token) {
      setToken(token);
      setIsAuthed(true);
    }

    const AdminToken = localStorage.getItem("admin-token");
    if (AdminToken) {
      setAdminToken(AdminToken);
      setIsAdminAuthed(true);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthed,
        token,
        Login,
        Logout,
        AdminToken,
        Adminauth,
        AdminLogout,
        isAdminAuthed,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
