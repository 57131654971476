import axios from "axios";

class API_Provider {
  api_url: string;

  constructor(API_URL: string) {
    this.api_url = API_URL;
  }

  async get(route: string, authToken?: string) {
    console.log(authToken);
    const call = await axios.get(
      `${this.api_url}${route}`,
      authToken
        ? {
            headers: {
              Authorization: authToken,
            },
          }
        : {}
    );

    if (!call.data) throw new Error("Faild to Call API services");

    if (!call.data.success) throw new Error(call.data.message);

    return call.data.data;
  }

  async post(route: string, payload: object, authToken?: string) {
    const call = await axios.post(
      `${this.api_url}${route}`,

      { ...payload },
      authToken
        ? {
            headers: {
              Authorization: authToken,
            },
          }
        : {}
    );

    if (!call.data) throw new Error("Faild to Call API services");

    if (!call.data.success) throw new Error(call.data.message);

    return route === "api/user/login" || route === "api/admin/login"
      ? call.headers["user-token"]
      : call.data.data;
  }
}

export default API_Provider;
