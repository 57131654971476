import React, { useContext, useState, createContext, useEffect } from "react";
import { useAuth } from "./Authcontext";
import { getUserInfo, getWebsites } from "../services/api-calls";

const UserContext = createContext({});
interface websitesData {
  domain: string;
  username: string;
  date_added: number;
}

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState<string | null>(null);
  const [websiteInfo, setWebsites] = useState<websitesData[]>();

  const { isAuthed, token } = useAuth();

  const getUser = async () => {
    try {
      const user_info = await getUserInfo(token);
      if (user_info) {
        setUserInfo(user_info);

        const websitesData = await getWebsites(token);

        if (websitesData) return setWebsites(websitesData);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (isAuthed) getUser();
  }, [isAuthed]);

  return (
    <UserContext.Provider
      value={{ userInfo, websiteInfo, setWebsites, getUser }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
