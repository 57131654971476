import React from "react";
import style from "../styles/component.module.css";
import LogoWeb from "../assets/images/logo-web.png";
import Plus from "../assets/images/Plus.png";
import { useNavigate } from "react-router";
export default function ToolsCard() {
  const Navigation = useNavigate();
  const ToolsData = [
    {
      name: "Connect Website",
      logo: LogoWeb,
      onClick: () => {
        Navigation("/website");
      },
    },
    {
      name: "Create AI Story",
      logo: Plus,
      onClick: () => {
        Navigation("/create");
      },
    },
  ];
  return (
    <div className={style.ToolsCard}>
      {ToolsData.map((e, _) => {
        return (
          <div onClick={e.onClick} key={_} className={style.ToolsCardBox}>
            <img src={e.logo} alt="logo" />
            <h2>{e.name}</h2>
          </div>
        );
      })}
    </div>
  );
}
