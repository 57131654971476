import React from "react";
import style from "./../styles/header.module.css";
import Button from "./button";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/Authcontext";
const LINKS = [];

export default function Header() {
  const Navigate = useNavigate();
  const { isAuthed } = useAuth();
  const ButtonClickEvent = () => {
    Navigate(isAuthed ? "/dashboard" : "/login");
  };
  return (
    <div className={style.NavBar}>
      <div className={style.NavBarComponents}>
        <div onClick={() => Navigate("/")} className={style.BrandLogo}>
          <h2>Rapid Stories</h2>
        </div>
        <div className={style.NavLinks}>
          <ul>
            {LINKS.map((e, _) => {
              return (
                <li key={_}>
                  <span>{e.name}</span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={style.UserAuth}>
          <div className={style.AuthButtons}>
            <Button
              width="125px"
              height="37px"
              text={isAuthed ? "Dashboard" : "Login"}
              type="primary"
              onClick={ButtonClickEvent}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
