import React from "react";
import style from "../styles/component.module.css";
interface props {
  CardContent: any[];
}
export default function DashboardCard(props: props) {
  return (
    <div className={style.DashboardCard}>
      {props.CardContent.map((e, _) => {
        return (
          <div key={_} className={style.CardBox}>
            <h2>{e.name}</h2>
            <span>
              {`${
                e.name === "Account"
                  ? "Trail"
                  : `${!e.value ? `${e.value}/${e.limit}` : e.value} `
              }`}
            </span>
          </div>
        );
      })}
    </div>
  );
}
