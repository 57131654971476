import React, { ChangeEvent, useEffect, useState } from "react";
import LoginForm from "../../components/loginform";
import isURL from "validator/lib/isURL";
import { AddWebsite, UnLinkWeb, getWebsites } from "../../services/api-calls";
import { useAuth } from "../../contexts/Authcontext";
import Table from "../../components/table";
import style from "../../styles/main.module.css";
import Button from "../../components/button";
import { useNavigate } from "react-router";
import Loaderbtn from "../../components/Loaderbtn";
import { toast } from "react-toastify";
import { useUser } from "../../contexts/UserContext";
const inputsForm = [
  {
    text: "Domain",
    placeHolder: "Enter domain address",
    id: "domain",
    type: "url",
  },
  {
    text: "Username",
    placeHolder: "Enter website username",
    id: "username",
    type: "text",
  },
  {
    text: "Password",
    placeHolder: "Enter website password",
    id: "password",
    type: "password",
  },
];
const Thead = ["Domain", "Username", "Date Added", "Action"];

interface inputConnectWebsite {
  domain: string;
  username: string;
  password: string;
}

export default function Website() {
  const Navigation = useNavigate();
  const [userInputs, setUserInputs] = useState<inputConnectWebsite>({
    domain: "",
    username: "",
    password: "",
  });

  const [Loading, setLoading] = useState(false);

  const [ShowConnect, setShowConnect] = useState<boolean>(false);

  //  const [userWebsites, setUserWebsites] = useState<websitesData[]>();

  const { websiteInfo, setWebsites, getUser } = useUser();

  const { token, isAuthed } = useAuth();

  const onChangeHanderl = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    setUserInputs((pre) => {
      if (name === "domain") {
        return { ...pre, domain: value };
      } else if (name === "username") {
        return { ...pre, username: value };
      } else if (name === "password") {
        return { ...pre, password: value };
      } else return pre;
    });
  };

  const ButtonAction = async () => {
    try {
      //check if website is valid

      if (!userInputs.domain || !userInputs.password || !userInputs.username) {
        throw new Error("Please enter all field !");
      }

      // const isUrlValid = isURL(userInputs.domain);

      // if (!isUrlValid) {
      //   throw new Error("Invalid url");
      // }

      setLoading(true);
      const Add = await AddWebsite(
        userInputs.username,
        userInputs.password,
        userInputs.domain,
        token
      );
      setLoading(false);
      setUserInputs(() => {
        return {
          domain: "",
          password: "",
          username: "",
        };
      });

      if (Add) {
        await getUser();
        return toast.success("Successfully Connected");
      }
      toast.error("faild adding website...");
    } catch (error) {
      setLoading(false);
      const errormess = (error as Error).message;
      toast.error(errormess);
    }
  };

  const UnLinkHander = async (domain: string) => {
    try {
      const Unlink = UnLinkWeb(domain, token);

      const data = await toast.promise(Unlink, {
        pending: "Unlinking website...",
      });

      if (data) {
        setWebsites(websiteInfo?.filter((a) => a.domain !== domain));

        return toast.success("Successfully Unlinked !");
      }
    } catch (error) {
      const message = (error as Error).message;
      toast.error(message);
    }
  };
  useEffect(() => {
    if (!isAuthed) return Navigation("/login");
  }, [isAuthed]);

  if (isAuthed)
    return (
      <div className={style.DashboardContainer}>
        <div className={style.Dashboard_Heading}>
          <h2>Connected Websites,</h2>
          <Button
            width="130px"
            height="40px"
            text={ShowConnect ? "Website Lists" : "Connect New"}
            onClick={() => {
              setShowConnect(!ShowConnect);
            }}
            type="secondary"
          />
        </div>
        {ShowConnect ? (
          <LoginForm
            Inputs={inputsForm}
            buttonText={Loading ? <Loaderbtn /> : "Connect"}
            titleText="Connect Website"
            onChangeHander={Loading ? () => {} : onChangeHanderl}
            value={userInputs}
            ButtonAction={ButtonAction}
          />
        ) : (
          websiteInfo &&
          websiteInfo.length && (
            <Table
              colums={Thead}
              rows={websiteInfo}
              onUnlinkHander={UnLinkHander}
            />
          )
        )}
      </div>
    );
  else return <></>;
}
