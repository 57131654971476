import React from "react";

import { ring2 } from "ldrs";

ring2.register();

// Default values shown

export default function Loaderbtn() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <l-ring-2
        size="28"
        stroke="4"
        stroke-length="0.25"
        bg-opacity="0.1"
        speed="0.8"
        color="white"
      ></l-ring-2>
    </div>
  );
}
