import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import { AuthProvider } from "./contexts/Authcontext";
import { UserProvider } from "./contexts/UserContext";
const id = document.getElementById("root");

const root = ReactDOM.createRoot(id!);
root.render(
  <AuthProvider>
    <UserProvider>
      <App />
    </UserProvider>
  </AuthProvider>
);
