import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/Authcontext";
import { useNavigate } from "react-router";
import DashboardCard from "../../components/dashboard-card";
import style from "../../styles/main.module.css";
import ToolsCard from "../../components/Tools-Card";
import Button from "../../components/button";
import { useUser } from "../../contexts/UserContext";

export default function Dashboard() {
  const Navigation = useNavigate();
  //@ts-ignore

  const { isAuthed, Logout } = useAuth();
  const [CardContent, setCardContent] = useState([
    { name: "Website", value: 0, limit: 0 },
    { name: "Account", value: 1, limit: 1 },
  ]);

  const { userInfo, websiteInfo } = useUser();

  useEffect(() => {
    if (!isAuthed) return Navigation("/login");
  }, [isAuthed]);

  useEffect(() => {
    if (websiteInfo?.length) {
      const websiteCount = websiteInfo.length as number;
      const newData = CardContent.map((a) => {
        if (a.name === "Website") {
          return { ...a, value: websiteCount };
        }
        return a;
      });
      setCardContent(newData);
    }
  }, [websiteInfo]);

  if (isAuthed && userInfo)
    return (
      <div className={style.DashboardContainer}>
        <div className={style.Dashboard_Heading}>
          <h2>Good Morning {userInfo.username},</h2>
          <Button
            width="100px"
            height="40px"
            text="Logout"
            onClick={Logout}
            type="secondary"
          />
        </div>
        <DashboardCard CardContent={CardContent} />
        <ToolsCard />
      </div>
    );
}
