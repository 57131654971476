import React, { ChangeEvent } from "react";
import style from "../styles/component.module.css";
import Button from "./button";

interface props {
  value: any;
  Inputs: { text: string; placeHolder: string; id: string; type: string }[];
  ButtonAction: () => void;
  buttonText: string | React.JSX.Element;
  titleText: string;
  onChangeHander: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default function LoginForm(props: props) {
  return (
    <div className={style.Form_Component}>
      <div className={style.Form_Main}>
        <div className={style.Form_Container}>
          <div className={style.FormHeading}>
            <h2>{props.titleText}</h2>
          </div>
          {props.Inputs.map((e, _) => {
            const value = props.value[e.id];
            return (
              <div key={_} className={style.Inputs_form}>
                <label htmlFor={e.id}>{e.text}</label>
                <input
                  type={e.type}
                  autoComplete="off"
                  value={value && value}
                  name={e.id}
                  onChange={(e) => props.onChangeHander(e)}
                  placeholder={e.placeHolder}
                />
              </div>
            );
          })}

          <div className={style.ActionButton}>
            <Button
              text={props.buttonText}
              type="primary"
              width={"100%"}
              height="49px"
              onClick={props.ButtonAction}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
