import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/auth/login";
import Signup from "./pages/auth/signup";
import Header from "./components/header";
import Dashboard from "./pages/user/dashboard";
import Website from "./pages/user/website";
import CreateStory from "./pages/user/createStory";
import AdminLogin from "./pages/admin/login";
import AdminDashboard from "./pages/admin/admin-dashboard";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App(): React.ReactElement {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/signup" Component={Signup} />
          <Route path="/login" Component={Login} />
          <Route path="/" Component={Dashboard} />{" "}
          <Route path="/dashboard" Component={Dashboard} />{" "}
          <Route path="/website" Component={Website} />{" "}
          <Route path="/create" Component={CreateStory} />{" "}
          <Route path="/admin/login" Component={AdminLogin} />{" "}
          <Route path="/admin/dashboard" Component={AdminDashboard} />{" "}
        </Routes>
      </Router>{" "}
      <ToastContainer />
    </>
  );
}

export default App;
