import React from "react";
import style from "./../styles/component.module.css";
import { FaUnlink } from "react-icons/fa";
import { getDate } from "../function";
import { MdDelete } from "react-icons/md";
import Button from "./button";

interface payload {
  rows: any[];
  AdminTable?: boolean;
  colums: string[];
  onUnlinkHander: (domain: string) => void;
  ActivateUser?: (email: string) => void;
}
export default function Table(props: payload) {
  return (
    <div className={style.Table}>
      <table>
        <thead>
          <tr>
            <th>#</th>
            {props.colums.map((e, _) => {
              return <th key={_}>{e}</th>;
            })}
          </tr>
        </thead>

        <tbody>
          {props.rows.map((e, _) => {
            return props.AdminTable ? (
              <tr key={_}>
                <td>{_ + 1}</td>
                <td>{e.username}</td>
                <td>{e.email}</td>
                <td>{getDate(e.time)}</td>
                <td
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {e.isVerified ? (
                    "Yes"
                  ) : (
                    <Button
                      type="secondary"
                      text="Activate"
                      width="100px"
                      height="30px"
                      onClick={() => props.ActivateUser!(e.email)}
                    />
                  )}
                </td>
                <td>
                  {" "}
                  <span style={{ color: "#de2f3d", cursor: "pointer" }}>
                    <MdDelete
                      size={20}
                      onClick={() => props.onUnlinkHander(e.email)}
                    />
                  </span>
                </td>
              </tr>
            ) : (
              <tr key={_}>
                <td>{_ + 1}</td>
                <td>{e.domain}</td>
                <td>{e.username}</td>
                <td>{getDate(e.date_added)}</td>
                <td>
                  <span style={{ color: "#de2f3d", cursor: "pointer" }}>
                    <FaUnlink onClick={() => props.onUnlinkHander(e.domain)} />
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
