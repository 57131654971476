import React, { ChangeEvent, useEffect, useState } from "react";
import style from "../../styles/main.module.css";
import LoginForm from "../../components/loginform";
import { CreateStorys } from "../../services/api-calls";
import { useAuth } from "../../contexts/Authcontext";
import { useNavigate } from "react-router";
import Loaderbtn from "../../components/Loaderbtn";
import { toast } from "react-toastify";
import { useUser } from "../../contexts/UserContext";

const inputsForm = [
  {
    text: "Query",
    placeHolder: "Enter query to create story",
    id: "query",
    type: "text",
  },
  {
    text: "Slides",
    placeHolder: "Enter Number of Slides",
    id: "slides",
    type: "number",
  },
  {
    text: "Website",
    placeHolder: "Enter website to Post",
    id: "website",
    type: "text",
  },
  {
    text: "Text Color",
    placeHolder: "Enter text color",
    id: "textcolor",
    type: "color",
  },
  {
    text: "Backgound Color",
    placeHolder: "Enter background color",
    id: "bgcolor",
    type: "color",
  },
  {
    text: "Button Text",
    placeHolder: "Enter button text",
    id: "button_text",
    type: "button_text",
  },
  {
    text: "Button URL",
    placeHolder: "Enter button url",
    id: "button_url",
    type: "button_url",
  },
];
interface CreateStory {
  query: string;
  slides: number;
  domain: string;
  bgcolor: string;
  button_text: string;
  button_url: string;
  textcolor: string;
}

export default function CreateStory() {
  const Navigation = useNavigate();

  const { token, isAuthed } = useAuth();
  const { websiteInfo } = useUser();
  const [Loading, setLoading] = useState(false);

  const [storyPayload, setStoryPayload] = useState<CreateStory>({
    query: "",
    domain: "",
    slides: 7,
    textcolor: "#3c48cf",
    bgcolor: "#ede1e4",
    button_text: "Click Me",
    button_url: "",
  });

  const onChangeHanderl = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    setStoryPayload((pre) => {
      if (name === "query") {
        return { ...pre, query: value };
      } else if (name === "slides") {
        return { ...pre, slides: value };
      } else if (name === "website") {
        return { ...pre, domain: value };
      } else if (name === "bgcolor") {
        return { ...pre, bgcolor: value };
      } else if (name === "textcolor") {
        return { ...pre, textcolor: value };
      } else if (name === "button_text") {
        return { ...pre, button_text: value };
      } else if (name === "button_url") {
        return { ...pre, button_url: value };
      } else return pre;
    });
  };
  const ButtonAction = async () => {
    try {
      if (!storyPayload.query || !storyPayload.slides || !storyPayload.domain) {
        return toast.error("Please fill all the forms");
      }
      setLoading(true);
      const Create = await CreateStorys(storyPayload, token);
      setLoading(false);
      if (Create) return toast.success("Success");

      toast.error("Faild to create story");
    } catch (error) {
      setLoading(false);
      const message = (error as Error).message;
      toast.error(message);
    }
  };

  useEffect(() => {
    if (!isAuthed) return Navigation("/login");
  }, [isAuthed]);

  useEffect(() => {
    if (websiteInfo?.length)
      setStoryPayload((pre) => {
        return {
          ...pre,
          domain: websiteInfo[0].domain,
        };
      });
  }, [websiteInfo]);

  if (isAuthed)
    return (
      <div className={style.DashboardContainer}>
        <div className={style.Dashboard_Heading}>
          <h2>Create AI Story,</h2>
        </div>

        <LoginForm
          Inputs={inputsForm}
          buttonText={Loading ? <Loaderbtn /> : "Create"}
          titleText="Create New Story"
          onChangeHander={Loading ? () => {} : onChangeHanderl}
          value={storyPayload}
          ButtonAction={ButtonAction}
        />
      </div>
    );
  else return <></>;
}
