import React from "react";
import style from "./../styles/header.module.css";

interface props {
  text: string | React.JSX.Element;
  type: "primary" | "secondary";
  width: string;
  height: string;
  onClick: () => void;
}

export default function Button(props: props) {
  return (
    <button
      style={{
        width: props.width,
        height: props.height,
        backgroundColor: props.type === "secondary" ? "#946DE7" : "",
        border: props.type === "secondary" ? "#946DE7" : "",
        borderRadius: props.type === "secondary" ? "9px" : "",
      }}
      onClick={props.onClick}
      className={style.Button}
    >
      {props.text}
    </button>
  );
}
