import React, { ChangeEvent, useEffect, useState } from "react";
import LoginForm from "../../components/loginform";
import style from "../../styles/main.module.css";
import { useNavigate } from "react-router";
import { LoginUser } from "../../services/api-calls";
import { useAuth } from "../../contexts/Authcontext";
import Loaderbtn from "../../components/Loaderbtn";
import { toast } from "react-toastify";

interface inputLogin {
  email: string;
  password: string;
}

const inputsForm = [
  {
    text: "Email",
    placeHolder: "Enter email address",
    id: "email",
    type: "email",
  },
  {
    text: "Password",
    placeHolder: "Enter password address",
    id: "password",
    type: "password",
  },
];

export default function Login() {
  const [userInputs, setUserInputs] = useState<inputLogin>({
    email: "",
    password: "",
  });
  const { Login, isAuthed } = useAuth();
  const Navigation = useNavigate();
  const [Loading, setLoading] = useState(false);

  const HandleInputs = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    setUserInputs((pre) => {
      if (name === "email") {
        return {
          ...pre,
          email: value,
        };
      } else if (name === "password") {
        return {
          ...pre,
          password: value,
        };
      } else return pre;
    });
  };

  const HandleOnClick = async () => {
    try {
      if (!userInputs.email) {
        return toast.error("Required Valid Email");
      }
      if (!userInputs.password) {
        return toast.error("Required Valid Password");
      }
      setLoading(true);
      const LoginToken = await LoginUser(userInputs);
      setLoading(false);

      if (!LoginToken) throw new Error("Faild to login into account");

      Login(LoginToken);

      toast.success("Login success");
      setUserInputs(() => {
        return { email: "", password: "" };
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      const message = (error as Error).message;
      toast.error("Error : " + message);
    }
  };

  useEffect(() => {
    if (isAuthed) return Navigation("/dashboard");
  }, [isAuthed]);

  if (!isAuthed)
    return (
      <div>
        <LoginForm
          Inputs={inputsForm}
          buttonText={Loading ? <Loaderbtn /> : "Login"}
          ButtonAction={Loading ? () => {} : HandleOnClick}
          titleText="Welcome Back"
          value={userInputs}
          onChangeHander={HandleInputs}
        />
        <div className={style.blowText}>
          <span>
            Did not have Account ?{" "}
            <strong onClick={() => Navigation("/signup")}>Signup</strong>{" "}
          </span>
        </div>
      </div>
    );
  else return <></>;
}
