import React, { ChangeEvent, useEffect, useState } from "react";
import LoginForm from "../../components/loginform";
import { useNavigate } from "react-router";
import { LoginAdmin } from "../../services/api-calls";
import { useAuth } from "../../contexts/Authcontext";
import Loaderbtn from "../../components/Loaderbtn";
import { toast } from "react-toastify";

interface inputAdminLogin {
  email: string;
  password: string;
}

const inputsForm = [
  {
    text: "Admin Email",
    placeHolder: "Enter admin email address",
    id: "email",
    type: "email",
  },
  {
    text: "Admin Password",
    placeHolder: "Enter admin password address",
    id: "password",
    type: "password",
  },
];

export default function AdminLogin() {
  const [userInputs, setUserInputs] = useState<inputAdminLogin>({
    email: "",
    password: "",
  });
  const Navigation = useNavigate();
  const { Adminauth, isAdminAuthed } = useAuth();
  const [loading, setLoading] = useState(false);

  const HandleInputs = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    setUserInputs((pre) => {
      if (name === "email") {
        return {
          ...pre,
          email: value,
        };
      } else if (name === "password") {
        return {
          ...pre,
          password: value,
        };
      } else return pre;
    });
  };

  const HandleOnClick = async () => {
    try {
      if (!userInputs.email) {
        return toast.error("Required Valid Email");
      }
      if (!userInputs.password) {
        return toast.error("Required Valid Password");
      }

      setLoading(true);
      const LoginToken = await LoginAdmin(userInputs);
      setLoading(false);
      if (!LoginToken) throw new Error("Faild to login into account");

      toast.success("Login success");
      Adminauth(LoginToken);

      setUserInputs(() => {
        return { email: "", password: "" };
      });
    } catch (error) {
      setLoading(false);

      console.log(error);
      const message = (error as Error).message;
      toast.error("Error : " + message);
    }
  };
  useEffect(() => {
    if (isAdminAuthed) return Navigation("/admin/dashboard");
  }, [isAdminAuthed]);

  if (!isAdminAuthed)
    return (
      <div>
        <LoginForm
          Inputs={inputsForm}
          buttonText={loading ? <Loaderbtn /> : "Admin Login"}
          ButtonAction={loading ? () => {} : HandleOnClick}
          titleText="Welcome Back Admin"
          value={userInputs}
          onChangeHander={HandleInputs}
        />
      </div>
    );
  else return <></>;
}
